<template>
  <div>
    <div class="">
      <div @click="show_hospital = !show_hospital">
        <input
          class="
            bg-gray-200
            border-b border-white
            px-6
            w-full
            py-3
            font-medium
            focus:outline-none focus:border-primary
            rounded
          "
          type="text"
          placeholder="e.g National Veterinary Research Institute"
          readonly
          v-model="hospital_selected"
        />
        <div class="relative">
          <a href="javascript:;">
            <selectarrow-icon
              class="absolute right-0 -mt-9 mr-2"
              :width="'w-5'"
              :height="'h-5'"
              :color="'text-primary'"
            ></selectarrow-icon>
          </a>
        </div>
      </div>
      <transition name="localform">
        <div class="relative" v-if="show_hospital">
          <div
            class="
              bg-white
              absolute
              z-30
              rounded
              mt-1
              w-full
              border-t
              shadow-md
            "
          >
            <div class="overflow-y-scroll max-h-44">
              <p
                class="px-3 py-2 hover:bg-gray-300"
                :class="{
                  'rounded-t': index == 0,
                  'rounded-b': index + 1 == hospitals.length
                }"
                @click="selectHospitals(index)"
                v-for="(hospital, index) in hospitals"
                :key="index"
              >
                {{ hospital.name }}
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SelectarrowIcon from '../Icons/selectarrowIcon.vue'
import { mapState } from 'vuex'
export default {
  components: { SelectarrowIcon },
  data() {
    return {
      show_hospital: false,
      hospital_selected: '',
      hospital_index: null,
      payload: ''
    }
  },
  created() {
    this.$store.dispatch('hospital_list/getHospital')
  },
  computed: mapState('hospital_list', ['hospitals']),
  watch: {
    hospital_selected() {
      this.$emit('add-hospital', this.hospital_index)
    }
  },
  methods: {
    selectHospitals(val) {
      this.hospital_selected = this.hospitals[val].name
      this.hospital_index = this.hospitals[val].id
      this.show_hospital = false
    }
  }
}
</script>

<style>
.localform-enter-active,
.localform-leave-active {
  transition: transition ease-in, opacity ease-in-out;
  @apply duration-300;
}

.localform-enter,
.localform-leave-to {
  opacity: 0;
}

.localform-leave,
.localform-enter-to {
  opacity: 1;
}
</style>
