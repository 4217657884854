<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="
      margin: auto;
      background: none;
      display: block;
      shape-rendering: auto;
    "
    class="fill-current inline-block"
    :class="[width, height, color]"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#c5dddb"
      stroke-width="10"
      r="35"
      stroke-dasharray="164.93361431346415 56.97787143782138"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: 'w-5'
    },
    height: {
      type: String,
      default: 'h-5'
    },
    color: {
      type: String,
      default: 'text-card-4'
    }
  }
}
</script>
