<template>
  <div>
    <svg
      class="animated"
      id="freepik_stories-filing-system"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:svgjs="http://svgjs.com/svgjs"
    >
      <g
        id="freepik--background-complete--inject-20"
        class="animable"
        style="transform-origin: 258.245px 246.373px"
      >
        <rect
          x="275.2"
          y="39.73"
          width="140.58"
          height="252.55"
          style="fill: rgb(235, 235, 235); transform-origin: 345.49px 166.005px"
          id="elg4vesuf2aun"
          class="animable"
        ></rect>
        <rect
          x="280.82"
          y="47.05"
          width="127.9"
          height="237.7"
          style="fill: rgb(250, 250, 250); transform-origin: 344.77px 165.9px"
          id="eln9tiaxhbraq"
          class="animable"
        ></rect>
        <polygon
          points="408.72 49.85 280.82 212.54 280.82 47.05 408.72 47.05 408.72 49.85"
          style="fill: rgb(245, 245, 245); transform-origin: 344.77px 129.795px"
          id="el541b01qwvc"
          class="animable"
        ></polygon>
        <rect
          x="280.82"
          y="115.43"
          width="130.68"
          height="2.53"
          style="fill: rgb(235, 235, 235); transform-origin: 346.16px 116.695px"
          id="el0tv9rhkwpe9b"
          class="animable"
        ></rect>
        <rect
          x="280.82"
          y="172.03"
          width="130.68"
          height="2.53"
          style="fill: rgb(235, 235, 235); transform-origin: 346.16px 173.295px"
          id="elvv6s8al7ztk"
          class="animable"
        ></rect>
        <rect
          x="280.82"
          y="228.64"
          width="130.68"
          height="2.53"
          style="fill: rgb(235, 235, 235); transform-origin: 346.16px 229.905px"
          id="el1afmgk9bm27"
          class="animable"
        ></rect>
        <g id="elz91it3txo39">
          <rect
            x="257.37"
            y="200.86"
            width="174.79"
            height="6.46"
            style="
              fill: rgb(235, 235, 235);
              transform-origin: 344.765px 204.09px;
              transform: rotate(-90deg);
            "
            class="animable"
          ></rect>
        </g>
        <rect
          x="305.45"
          y="47.05"
          width="2.93"
          height="69.64"
          style="fill: rgb(235, 235, 235); transform-origin: 306.915px 81.87px"
          id="elncl7do2ar9d"
          class="animable"
        ></rect>
        <rect
          x="330.69"
          y="47.05"
          width="2.93"
          height="69.64"
          style="fill: rgb(235, 235, 235); transform-origin: 332.155px 81.87px"
          id="elzpev90wx2c"
          class="animable"
        ></rect>
        <rect
          x="355.94"
          y="47.05"
          width="2.93"
          height="69.64"
          style="fill: rgb(235, 235, 235); transform-origin: 357.405px 81.87px"
          id="el1i7a3bv34ak"
          class="animable"
        ></rect>
        <rect
          x="381.19"
          y="47.05"
          width="2.93"
          height="69.64"
          style="fill: rgb(235, 235, 235); transform-origin: 382.655px 81.87px"
          id="elkge5hikkwa8"
          class="animable"
        ></rect>
        <rect
          x="269.25"
          y="35.44"
          width="5.95"
          height="258.39"
          style="
            fill: rgb(245, 245, 245);
            transform-origin: 272.225px 164.635px;
          "
          id="eln08wpt2b1p"
          class="animable"
        ></rect>
        <polygon
          points="421.73 294.55 415.78 292.27 415.78 35.44 421.73 35.44 421.73 294.55"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 418.755px 164.995px;
          "
          id="elvb5hdi5kaw8"
          class="animable"
        ></polygon>
        <rect
          x="269.25"
          y="32.84"
          width="152.47"
          height="6.89"
          style="fill: rgb(224, 224, 224); transform-origin: 345.485px 36.285px"
          id="elkmaf7nvk44"
          class="animable"
        ></rect>
        <rect
          x="265.04"
          y="295.4"
          width="160.72"
          height="6.42"
          style="fill: rgb(224, 224, 224); transform-origin: 345.4px 298.61px"
          id="elaiok4w19lk7"
          class="animable"
        ></rect>
        <polygon
          points="415.78 292.27 425.75 295.4 265.04 295.4 275.2 292.27 415.78 292.27"
          style="
            fill: rgb(235, 235, 235);
            transform-origin: 345.395px 293.835px;
          "
          id="el6gbzedlgwjo"
          class="animable"
        ></polygon>
        <path
          d="M275.67,458.39c16.77-10.07,21.84-30.57,22.5-49.27.11-3.12.68-6.38-.61-9.21s-4.62-4.94-7.5-3.82c-2.39.92-3.58,3.54-5,5.68a18.27,18.27,0,0,1-7.78,6.47c-2.06.89-4.56,1.35-6.44.13-2.58-1.66-2.64-5.4-2.41-8.49q.66-8.65,1.31-17.31a28.72,28.72,0,0,0-.3-9.26c-.76-3-2.69-5.89-5.58-6.92s-6.65.42-7.49,3.41a28.84,28.84,0,0,0-.35,3.85c-.18,1.28-.87,2.66-2.12,2.93s-2.44-.68-3.3-1.65c-3-3.35-4.87-7.53-6.62-11.68s-3.44-8.38-6.1-12-6.49-6.6-10.91-7.16-9.33,1.86-10.75,6.13.81,8.9,3.34,12.62A69.2,69.2,0,0,0,233,377.57a5.65,5.65,0,0,1,2.23,2.76c.53,2.11-1.57,4-3.65,4.56-2.37.61-4.85.2-7.29.31s-5.12,1-6.19,3.22c-1.49,3.08.93,6.58,3.38,8.94a59.42,59.42,0,0,0,16.82,11.33c2.23,1,4.57,1.89,6.3,3.61s2.64,4.59,1.38,6.7-4.16,2.6-6.52,2-4.4-2.05-6.61-3.07c-4.15-1.9-9.66-2-12.64,1.5a9.84,9.84,0,0,0-1.65,8.59,22.53,22.53,0,0,0,4.17,8,59.63,59.63,0,0,0,24.19,19.64c9.63,4.1,18.72,5.66,28.78,2.75"
          style="
            fill: rgb(245, 245, 245);
            transform-origin: 257.084px 401.958px;
          "
          id="el86z0n5bat2n"
          class="animable"
        ></path>
        <path
          d="M272.63,459.29c-.17-7.44,1.14-3.32-1-12.92a277.36,277.36,0,0,0-8.94-30.64q-1.41-4-2.76-7.92c-.91-2.59-1.82-5.16-2.85-7.62a138.68,138.68,0,0,0-6.79-13.81c-2.37-4.29-4.77-8.27-7-12s-4.47-7.12-6.61-10.14a109.18,109.18,0,0,0-11.11-13.51c-1.43-1.49-2.58-2.6-3.37-3.35l-.9-.84a2.46,2.46,0,0,0-.32-.27,2,2,0,0,0,.28.31l.86.88c.76.78,1.88,1.92,3.29,3.43a115.86,115.86,0,0,1,10.94,13.58c2.11,3,4.28,6.45,6.54,10.16s4.64,7.7,7,12a141.07,141.07,0,0,1,6.72,13.77c1,2.45,1.92,5,2.83,7.6l2.76,7.91a286.06,286.06,0,0,1,9,30.56c2.13,9.57.86,5.41,1.07,12.82"
          style="fill: rgb(224, 224, 224); transform-origin: 246.927px 402.78px"
          id="elpbtx6dxg9mc"
          class="animable"
        ></path>
        <path
          d="M256.94,400.15a5.87,5.87,0,0,0,.4-1.3c.22-.84.5-2.07.82-3.6.66-3.06,1.41-7.3,2.23-12s1.63-8.91,2.32-11.95c.35-1.51.64-2.74.85-3.59a6.8,6.8,0,0,0,.27-1.33,5.81,5.81,0,0,0-.48,1.27c-.28.83-.63,2-1,3.56-.8,3-1.68,7.25-2.49,11.94s-1.51,8.81-2.06,12c-.26,1.44-.48,2.66-.65,3.63A6.3,6.3,0,0,0,256.94,400.15Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 260.385px 383.265px;
          "
          id="elrq4j1pwuobo"
          class="animable"
        ></path>
        <path
          d="M217.64,390a8.13,8.13,0,0,0,1.58.3c1,.16,2.48.39,4.29.73,3.62.64,8.59,1.71,14,3.13s10.29,2.93,13.77,4.12c1.75.58,3.15,1.09,4.12,1.43a8.22,8.22,0,0,0,1.52.49,8.44,8.44,0,0,0-1.44-.7c-.95-.41-2.33-1-4.07-1.61-3.46-1.29-8.31-2.86-13.76-4.29a143,143,0,0,0-14.09-3c-1.83-.28-3.31-.45-4.34-.54A8.36,8.36,0,0,0,217.64,390Z"
          style="fill: rgb(224, 224, 224); transform-origin: 237.28px 395.093px"
          id="elaebst5mtk1"
          class="animable"
        ></path>
        <path
          d="M271.22,445.6a2,2,0,0,0,.31-.47l.78-1.39c.67-1.21,1.6-3,2.72-5.21,2.23-4.43,5.13-10.64,8.24-17.54s5.94-13.14,8.08-17.61l2.55-5.28.69-1.44a1.81,1.81,0,0,0,.2-.53,3.42,3.42,0,0,0-.3.47l-.78,1.39c-.68,1.22-1.6,3-2.72,5.21-2.24,4.43-5.14,10.65-8.24,17.55s-5.94,13.13-8.08,17.6l-2.56,5.29-.68,1.44A2.14,2.14,0,0,0,271.22,445.6Z"
          style="
            fill: rgb(224, 224, 224);
            transform-origin: 283.005px 420.865px;
          "
          id="elssgoh7q3k6"
          class="animable"
        ></path>
        <path
          d="M219.49,424.88a2,2,0,0,0,.51.25l1.5.63L227,428c4.68,1.89,11.11,4.56,18.21,7.53s13.56,5.58,18.28,7.37c2.36.89,4.27,1.6,5.6,2.06l1.54.52a2.17,2.17,0,0,0,.55.14,2.2,2.2,0,0,0-.51-.25l-1.5-.62-5.54-2.23c-4.67-1.89-11.1-4.56-18.21-7.53s-13.55-5.58-18.27-7.37c-2.36-.89-4.27-1.6-5.61-2.05L220,425A2,2,0,0,0,219.49,424.88Z"
          style="fill: rgb(224, 224, 224); transform-origin: 245.335px 435.25px"
          id="elo7wubxeqtkr"
          class="animable"
        ></path>
        <rect
          x="159.41"
          y="71.2"
          width="37.81"
          height="12.17"
          style="fill: rgb(245, 245, 245); transform-origin: 178.315px 77.285px"
          id="elh1vvcqemujp"
          class="animable"
        ></rect>
        <rect
          x="194.87"
          y="95.54"
          width="37.81"
          height="12.17"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 213.775px 101.625px;
          "
          id="elsg561l52vym"
          class="animable"
        ></rect>
        <path
          d="M211.57,95.54a1.17,1.17,0,0,1,0-.23v-.63c0-.59,0-1.39,0-2.41,0-2.12-.05-5.14-.09-8.9l.17.17-37,.09c.11-.12-.27.26.25-.26h0V95.54l-.26-.25L201,95.4l7.77.07,2.09,0,.75,0a5.47,5.47,0,0,1-.68,0l-2,0-7.7.07-26.61.12h-.25v-6.2c0-1,0-2,0-3.08V83.39h0c.52-.52.15-.15.27-.26l37,.09h.17v.17c0,3.81-.07,6.86-.09,9,0,1,0,1.79,0,2.37S211.58,95.55,211.57,95.54Z"
          style="fill: rgb(224, 224, 224); transform-origin: 193.09px 89.3819px"
          id="elzy6qt658o8q"
          class="animable"
        ></path>
        <rect
          x="431.47"
          y="175.09"
          width="37.81"
          height="12.17"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 450.375px 181.175px;
          "
          id="el383yy60s34c"
          class="animable"
        ></rect>
        <path
          d="M482,174.78a1.17,1.17,0,0,1,0-.23c0-.17,0-.38,0-.63,0-.59,0-1.39,0-2.41,0-2.12-.05-5.13-.09-8.9l.17.17-37,.09c.11-.12-.27.26.25-.26h0v12.15l-.26-.25,26.43.11,7.76.08,2.1,0,.75,0a5.56,5.56,0,0,1-.69,0l-2,0-7.7.07L445,175h-.25v-6.19c0-1,0-2.05,0-3.09v-3.12h0c.52-.52.15-.15.27-.26l37,.09h.17v.17c0,3.81-.07,6.86-.09,9,0,1,0,1.79,0,2.37S482,174.79,482,174.78Z"
          style="fill: rgb(224, 224, 224); transform-origin: 463.47px 168.657px"
          id="elzql3gh0skgd"
          class="animable"
        ></path>
        <rect
          x="415.56"
          y="312.14"
          width="37.81"
          height="12.17"
          style="
            fill: rgb(245, 245, 245);
            transform-origin: 434.465px 318.225px;
          "
          id="elo0dtqvne4aj"
          class="animable"
        ></rect>
        <rect
          x="428.72"
          y="323.89"
          width="37.81"
          height="12.17"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 447.625px 329.975px;
          "
          id="elwd77cg150m"
          class="animable"
        ></rect>
        <path
          d="M442.52,348a1,1,0,0,1,0-.22l0-.64c0-.59,0-1.39,0-2.41,0-2.12-.05-5.13-.09-8.9l.17.17-37,.09c.11-.11-.27.26.25-.26h0V348l-.26-.25,26.43.12,7.76.07,2.1,0,.75,0a6.53,6.53,0,0,1-.69,0l-2,0-7.7.07-26.61.12h-.25v-6.2c0-1,0-2.05,0-3.09v-3.12h0c.52-.52.15-.14.27-.26l37,.09h.17v.17c0,3.81-.07,6.86-.09,9,0,1,0,1.8,0,2.38S442.53,348.05,442.52,348Z"
          style="fill: rgb(224, 224, 224); transform-origin: 424.1px 341.784px"
          id="elw3ierx5vpbs"
          class="animable"
        ></path>
        <rect
          x="34.3"
          y="147.25"
          width="37.81"
          height="12.17"
          style="fill: rgb(250, 250, 250); transform-origin: 53.205px 153.335px"
          id="eldnskjnw4skv"
          class="animable"
        ></rect>
        <path
          d="M88.82,147.25s0-.09,0-.23,0-.38,0-.63c0-.59,0-1.4,0-2.42,0-2.11-.06-5.13-.1-8.9l.17.17-37,.09.26-.25h0v6.22c0,2,0,4,0,5.95l-.25-.26,26.43.12,7.76.07,2.1,0a4,4,0,0,1,.74,0,5.27,5.27,0,0,1-.68,0l-2,0-7.7.07-26.6.12h-.25v-.25c0-1.93,0-3.91,0-5.95v-6.22h0c.52-.53.14-.15.26-.27l37,.09H89v.17c0,3.81-.08,6.86-.1,9,0,1,0,1.79,0,2.37A4.21,4.21,0,0,1,88.82,147.25Z"
          style="fill: rgb(224, 224, 224); transform-origin: 70.35px 141.014px"
          id="elbhzo3wjmj8m"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Floor--inject-20"
        class="animable"
        style="transform-origin: 249px 460.28px"
      >
        <path
          d="M477.39,460.3c0,.14-102.26.26-228.38.26s-228.4-.12-228.4-.26S122.85,460,249,460,477.39,460.16,477.39,460.3Z"
          style="fill: rgb(38, 50, 56); transform-origin: 249px 460.28px"
          id="el1jtrnf3tv89"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Box--inject-20"
        class="animable"
        style="transform-origin: 429.875px 431.955px"
      >
        <path
          d="M440.52,409.79l-1.34,24.76a1.68,1.68,0,0,1-1.66,1.58l-44.55.33a1.69,1.69,0,0,1-1.69-1.66l-.14-19.28a1.68,1.68,0,0,1,1.67-1.68l27.4,0a1.71,1.71,0,0,0,1.18-.49l4.73-4.73a1.66,1.66,0,0,1,1.17-.49l11.55-.08A1.66,1.66,0,0,1,440.52,409.79Z"
          style="fill: rgb(64, 123, 255); transform-origin: 415.831px 422.255px"
          id="elngfbpugpy5"
          class="animable"
        ></path>
        <g id="el8drct3ptcpo">
          <path
            d="M440.52,409.79l-1.34,24.76a1.68,1.68,0,0,1-1.66,1.58l-44.55.33a1.69,1.69,0,0,1-1.69-1.66l-.14-19.28a1.68,1.68,0,0,1,1.67-1.68l27.4,0a1.71,1.71,0,0,0,1.18-.49l4.73-4.73a1.66,1.66,0,0,1,1.17-.49l11.55-.08A1.66,1.66,0,0,1,440.52,409.79Z"
            style="opacity: 0.3; transform-origin: 415.831px 422.255px"
            class="animable"
          ></path>
        </g>
        <g id="eltz4kuid49ff">
          <rect
            x="391.22"
            y="410.75"
            width="48.68"
            height="29.09"
            style="
              fill: rgb(245, 245, 245);
              transform-origin: 415.56px 425.295px;
              transform: rotate(179.58deg);
            "
            class="animable"
          ></rect>
        </g>
        <path
          d="M443.68,413.15l-3.49,30.36a1.1,1.1,0,0,1-1.06,1l-47.44.34a1.06,1.06,0,0,1-1.08-1L388,413.51a1.06,1.06,0,0,1,1.06-1.16l53.55-.4A1.08,1.08,0,0,1,443.68,413.15Z"
          style="fill: rgb(64, 123, 255); transform-origin: 415.841px 428.4px"
          id="elx63g8xidg4"
          class="animable"
        ></path>
        <path
          d="M453.63,405.12l-1.34,24.76a1.68,1.68,0,0,1-1.66,1.58l-44.55.32a1.67,1.67,0,0,1-1.69-1.65l-.14-19.28a1.67,1.67,0,0,1,1.67-1.68l27.4,0a1.67,1.67,0,0,0,1.18-.48l4.73-4.73a1.66,1.66,0,0,1,1.17-.49l11.55-.08A1.67,1.67,0,0,1,453.63,405.12Z"
          style="fill: rgb(64, 123, 255); transform-origin: 428.941px 417.585px"
          id="eleol1pqh4rfs"
          class="animable"
        ></path>
        <g id="elk78emkz78r">
          <path
            d="M453.63,405.12l-1.34,24.76a1.68,1.68,0,0,1-1.66,1.58l-44.55.32a1.67,1.67,0,0,1-1.69-1.65l-.14-19.28a1.67,1.67,0,0,1,1.67-1.68l27.4,0a1.67,1.67,0,0,0,1.18-.48l4.73-4.73a1.66,1.66,0,0,1,1.17-.49l11.55-.08A1.67,1.67,0,0,1,453.63,405.12Z"
            style="opacity: 0.3; transform-origin: 428.941px 417.585px"
            class="animable"
          ></path>
        </g>
        <g id="elqwjrfafraxa">
          <rect
            x="404.32"
            y="406.08"
            width="48.68"
            height="29.09"
            style="
              fill: rgb(245, 245, 245);
              transform-origin: 428.66px 420.625px;
              transform: rotate(179.58deg);
            "
            class="animable"
          ></rect>
        </g>
        <path
          d="M456.79,408.48l-3.5,30.36a1.06,1.06,0,0,1-1.06,1l-47.44.35a1.07,1.07,0,0,1-1.08-1l-2.61-30.32a1.07,1.07,0,0,1,1.07-1.16l53.54-.4A1.08,1.08,0,0,1,456.79,408.48Z"
          style="fill: rgb(64, 123, 255); transform-origin: 428.945px 423.75px"
          id="elhxmwt24x43"
          class="animable"
        ></path>
        <g id="el0otgtxcds4g">
          <path
            d="M456.79,408.48l-3.5,30.36a1.06,1.06,0,0,1-1.06,1l-47.44.35a1.07,1.07,0,0,1-1.08-1l-2.61-30.32a1.07,1.07,0,0,1,1.07-1.16l53.54-.4A1.08,1.08,0,0,1,456.79,408.48Z"
            style="opacity: 0.3; transform-origin: 428.945px 423.75px"
            class="animable"
          ></path>
        </g>
        <path
          d="M468.4,410.92l-1.34,24.75a1.68,1.68,0,0,1-1.66,1.59l-44.55.32a1.68,1.68,0,0,1-1.69-1.66L419,416.65a1.68,1.68,0,0,1,1.67-1.68l27.4,0a1.62,1.62,0,0,0,1.18-.49l4.73-4.72a1.66,1.66,0,0,1,1.17-.49l11.55-.09A1.67,1.67,0,0,1,468.4,410.92Z"
          style="fill: rgb(64, 123, 255); transform-origin: 443.701px 423.38px"
          id="elo29alhqwc4"
          class="animable"
        ></path>
        <g id="elg7ai6pyq16o">
          <rect
            x="419.1"
            y="411.88"
            width="48.68"
            height="29.09"
            style="
              fill: rgb(245, 245, 245);
              transform-origin: 443.44px 426.425px;
              transform: rotate(179.58deg);
            "
            class="animable"
          ></rect>
        </g>
        <path
          d="M471.56,414.28l-3.49,30.36a1.08,1.08,0,0,1-1.06.95l-47.45.35a1.06,1.06,0,0,1-1.07-1l-2.61-30.32a1.07,1.07,0,0,1,1.06-1.17l53.54-.39A1.08,1.08,0,0,1,471.56,414.28Z"
          style="fill: rgb(64, 123, 255); transform-origin: 443.722px 429.5px"
          id="el173v69whgge"
          class="animable"
        ></path>
        <path
          d="M415.87,418.41v42.11h60.76V418.41ZM435.7,426a3.11,3.11,0,0,1,3.11-3.11h14.88a3.11,3.11,0,1,1,0,6.21H438.81A3.11,3.11,0,0,1,435.7,426Z"
          style="fill: rgb(69, 90, 100); transform-origin: 446.25px 439.465px"
          id="elomai1ikmh"
          class="animable"
        ></path>
        <path
          d="M383.12,418.41v42.11h32.75V418.41Z"
          style="fill: rgb(38, 50, 56); transform-origin: 399.495px 439.465px"
          id="elz2h5kz5n7dh"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Character--inject-20"
        class="animable"
        style="transform-origin: 304.055px 280.666px"
      >
        <path
          d="M268.59,428.71,265.42,447s-19.65,2.92-20.56,6.24l35.22,7.42,6.11-28.28Z"
          style="fill: rgb(64, 123, 255); transform-origin: 265.525px 444.685px"
          id="el9xkc2qbih4t"
          class="animable"
        ></path>
        <g id="elcow81aflthi">
          <g
            style="opacity: 0.6; transform-origin: 276.012px 448.987px"
            class="animable"
          >
            <path
              d="M276.63,447.76a1.48,1.48,0,0,1,.65,1.85,1.41,1.41,0,0,1-1.83.66,1.55,1.55,0,0,1-.68-2,1.46,1.46,0,0,1,2-.47"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 276.012px 448.987px;
              "
              id="elu6gd2597bgm"
              class="animable"
            ></path>
          </g>
        </g>
        <g id="el1voxg65xoao">
          <path
            d="M280.08,460.65l.5-2.87-34-6s-1.71.35-1.76,1.49Z"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.6;
              transform-origin: 262.7px 456.215px;
            "
            class="animable"
          ></path>
        </g>
        <path
          d="M264.88,446.7c0,.17.82.43,1.55,1.16s1,1.55,1.2,1.51.12-1.08-.78-1.95S264.88,446.53,264.88,446.7Z"
          style="fill: rgb(38, 50, 56); transform-origin: 266.3px 447.994px"
          id="el5lrlxbbggng"
          class="animable"
        ></path>
        <path
          d="M260.87,447.45c-.08.15.61.6,1,1.45s.43,1.66.61,1.69.48-.92-.07-2S260.91,447.28,260.87,447.45Z"
          style="fill: rgb(38, 50, 56); transform-origin: 261.794px 448.995px"
          id="elxkv8l871ag9"
          class="animable"
        ></path>
        <path
          d="M257.84,451.42c.15.07.57-.73.32-1.77s-1-1.58-1.07-1.45.29.74.48,1.59S257.67,451.37,257.84,451.42Z"
          style="fill: rgb(38, 50, 56); transform-origin: 257.659px 449.803px"
          id="elvh025piffgh"
          class="animable"
        ></path>
        <path
          d="M266.07,442.61c0,.17.88.1,1.83.37s1.66.74,1.78.61-.48-.9-1.62-1.2S266,442.46,266.07,442.61Z"
          style="fill: rgb(38, 50, 56); transform-origin: 267.881px 442.944px"
          id="eljua3mpyhzu"
          class="animable"
        ></path>
        <g id="eltd1g7gtqs5">
          <polygon
            points="267.96 432.34 267.39 435.59 284.74 439.08 285.38 436.17 267.96 432.34"
            style="opacity: 0.3; transform-origin: 276.385px 435.71px"
            class="animable"
          ></polygon>
        </g>
        <path
          d="M266.78,438.93a3.4,3.4,0,0,0,1.76.37,8.47,8.47,0,0,0,2-.18,8.76,8.76,0,0,0,1.12-.33,2.06,2.06,0,0,0,.62-.3.77.77,0,0,0,.28-.9,1,1,0,0,0-.68-.55,2.73,2.73,0,0,0-.68-.05,6.24,6.24,0,0,0-1.17.15,6.1,6.1,0,0,0-1.85.72c-1,.59-1.4,1.17-1.34,1.22s.57-.38,1.54-.83a6.75,6.75,0,0,1,1.76-.56,7.34,7.34,0,0,1,1.08-.1c.39,0,.71.05.77.23s0,.1-.08.19a1.64,1.64,0,0,1-.46.22,9,9,0,0,1-1,.34,10,10,0,0,1-1.85.29A13.2,13.2,0,0,0,266.78,438.93Z"
          style="fill: rgb(38, 50, 56); transform-origin: 269.694px 438.146px"
          id="elzwcsll45le"
          class="animable"
        ></path>
        <path
          d="M267,439.23a3,3,0,0,0,.82-1.61,5.83,5.83,0,0,0,.17-2,6.36,6.36,0,0,0-.23-1.17,1.46,1.46,0,0,0-.93-1.12.83.83,0,0,0-.84.33,2.4,2.4,0,0,0-.32.61,4,4,0,0,0-.25,1.19,4.13,4.13,0,0,0,.37,2c.49,1.06,1.17,1.41,1.19,1.36s-.45-.53-.79-1.52a4.16,4.16,0,0,1-.21-1.78,3.4,3.4,0,0,1,.25-1c.13-.36.34-.63.47-.57s.41.38.49.71a6.07,6.07,0,0,1,.24,1.05,6.87,6.87,0,0,1,0,1.83C267.18,438.58,266.86,439.18,267,439.23Z"
          style="fill: rgb(38, 50, 56); transform-origin: 266.712px 436.272px"
          id="elvgqsyalhkgf"
          class="animable"
        ></path>
        <path
          d="M388.26,429.12l-7.62-9.95-.39-.51-13,9.65,12,13.2-.44,13.36c-6.69,1.73-7.7,5.07-7.7,5.07h13.91l12.64-24.34Z"
          style="fill: rgb(64, 123, 255); transform-origin: 382.455px 439.3px"
          id="elkj8h3mpsnti"
          class="animable"
        ></path>
        <g id="elqjeqxrshfe">
          <polygon
            points="397.7 435.59 385.06 459.93 371.15 459.94 372.72 457.85 384.03 457.22 395.69 434.21 397.7 435.59"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.6;
              transform-origin: 384.425px 447.075px;
            "
            class="animable"
          ></polygon>
        </g>
        <path
          d="M378.93,448.75c.11.14.91-.32,2-.29s1.87.48,2,.34-.66-.94-2-.95S378.81,448.64,378.93,448.75Z"
          style="fill: rgb(38, 50, 56); transform-origin: 380.931px 448.338px"
          id="ely9oh9zzyry"
          class="animable"
        ></path>
        <path
          d="M379.16,445.43c.14.1.75-.72,1.86-.94s2,.29,2.09.15-.11-.3-.5-.51a2.6,2.6,0,0,0-3.19.64C379.15,445.12,379.09,445.39,379.16,445.43Z"
          style="fill: rgb(38, 50, 56); transform-origin: 381.132px 444.638px"
          id="elwpb1u0qhha"
          class="animable"
        ></path>
        <path
          d="M379.06,441.18c.15.07.63-.73,1.6-1.07s1.84,0,1.91-.17-.14-.27-.53-.41a2.42,2.42,0,0,0-2.83,1C379,440.89,379,441.15,379.06,441.18Z"
          style="fill: rgb(38, 50, 56); transform-origin: 380.804px 440.292px"
          id="elwfqlijl6yng"
          class="animable"
        ></path>
        <path
          d="M375.34,437.88c.16.06.55-.73,1.42-1.29s1.72-.65,1.73-.82-1-.4-2.07.31S375.17,437.86,375.34,437.88Z"
          style="fill: rgb(38, 50, 56); transform-origin: 376.887px 436.739px"
          id="elkqjsrxyskgf"
          class="animable"
        ></path>
        <path
          d="M373.52,435a6.17,6.17,0,0,0,1.21-1.46l1.18-1.67a4.55,4.55,0,0,0,.64-1.13,1.08,1.08,0,0,0-.08-.84.85.85,0,0,0-.84-.44,2.59,2.59,0,0,0-1.3.67,5.1,5.1,0,0,0-.85,1,4.72,4.72,0,0,0-.72,2,2.57,2.57,0,0,0,.31,1.9,16.17,16.17,0,0,1,.11-1.83,4.55,4.55,0,0,1,1.53-2.59,2.18,2.18,0,0,1,1-.52.26.26,0,0,1,.27.12.54.54,0,0,1,0,.4,4.73,4.73,0,0,1-.53,1l-1.07,1.74A6,6,0,0,0,373.52,435Z"
          style="fill: rgb(38, 50, 56); transform-origin: 374.649px 432.242px"
          id="elhe8meiql6lq"
          class="animable"
        ></path>
        <path
          d="M373,435.44c.07,0,0-.74-.74-1.57a3.46,3.46,0,0,0-4.12-.74.61.61,0,0,0-.22.49.74.74,0,0,0,.17.44,1.94,1.94,0,0,0,.5.44,5.72,5.72,0,0,0,1,.56,6.05,6.05,0,0,0,1.83.51,2.77,2.77,0,0,0,1.72-.16c0-.1-.66-.05-1.65-.26a6.54,6.54,0,0,1-1.65-.6A5.49,5.49,0,0,1,369,434a1.46,1.46,0,0,1-.36-.31c-.07-.12-.05-.09,0-.12a1.07,1.07,0,0,1,.4-.15,3.12,3.12,0,0,1,.52-.08,3.24,3.24,0,0,1,1,.07,3.38,3.38,0,0,1,1.5.76C372.72,434.84,372.93,435.48,373,435.44Z"
          style="fill: rgb(38, 50, 56); transform-origin: 370.53px 434.196px"
          id="elndq11564cwp"
          class="animable"
        ></path>
        <g id="elr7xzfmolw2e">
          <circle
            cx="388.25"
            cy="435.17"
            r="1.65"
            style="
              fill: rgb(255, 255, 255);
              opacity: 0.6;
              transform-origin: 388.25px 435.17px;
            "
            class="animable"
          ></circle>
        </g>
        <g id="elicegb1vamub">
          <polygon
            points="368.48 429.64 371.15 432.57 382.38 421.44 380.82 419.41 368.48 429.64"
            style="opacity: 0.3; transform-origin: 375.43px 425.99px"
            class="animable"
          ></polygon>
        </g>
        <path
          d="M300.54,250.53s-13,44.12-24.62,94c-7,30.24-13.65,86.51-13.65,86.51l30.86,6.8,13.23-72.34,14.12-42.82c4.85-13.52,11.82-23.4,20.28-30.72,9.2-13,14-27.87,12.15-43.7h0Z"
          style="fill: rgb(38, 50, 56); transform-origin: 307.786px 343.05px"
          id="elo0wt1ppos8"
          class="animable"
        ></path>
        <path
          d="M298,257.56l11.53,95c1.12,9.17,2.67,18,8.9,24.83l46.69,55.37L388.25,412l-49.49-67.66,8.6-95.38-48.42,8.26"
          style="fill: rgb(38, 50, 56); transform-origin: 343.125px 340.86px"
          id="eljzqadoqfhmn"
          class="animable"
        ></path>
        <path
          d="M339.23,343.63c.09.17-2,1.21-4,3.28s-2.89,4.21-3.06,4.13.54-2.41,2.6-4.56S339.19,343.47,339.23,343.63Z"
          style="fill: rgb(69, 90, 100); transform-origin: 335.689px 347.327px"
          id="elh4yzineyf3"
          class="animable"
        ></path>
        <path
          d="M338.53,344.34c0,.18-1.85.12-4.07.34s-4,.62-4.07.44a8.77,8.77,0,0,1,4-1.07C336.69,343.82,338.55,344.17,338.53,344.34Z"
          style="fill: rgb(69, 90, 100); transform-origin: 334.46px 344.57px"
          id="elms1b4yfm49c"
          class="animable"
        ></path>
        <path
          d="M351.52,269.84a7.71,7.71,0,0,1-.17,1.08c-.08.38-.16.81-.26,1.31s-.31,1.1-.5,1.75a41.24,41.24,0,0,1-5.12,10.38c-2.7,4.11-6.45,8.58-10.44,13.64a84,84,0,0,0-5.8,8.25,100.41,100.41,0,0,0-5.05,9.58c-3.11,6.76-5.62,13.4-7.75,19.49s-3.9,11.62-5.4,16.27-2.68,8.35-3.54,11c-.42,1.25-.76,2.25-1,3a6.93,6.93,0,0,1-.4,1,6.94,6.94,0,0,1,.27-1.07l.89-3,3.34-11.08c1.44-4.66,3.17-10.22,5.27-16.33s4.6-12.78,7.72-19.57a98.34,98.34,0,0,1,5.11-9.63,80,80,0,0,1,5.87-8.29c4-5.06,7.8-9.48,10.52-13.52a43.25,43.25,0,0,0,5.27-10.2c.2-.65.41-1.22.55-1.73s.23-.92.32-1.28A6.69,6.69,0,0,1,351.52,269.84Z"
          style="fill: rgb(69, 90, 100); transform-origin: 328.805px 318.215px"
          id="elg60uw6x0mnr"
          class="animable"
        ></path>
        <path
          d="M240,223.1l-8.11,4.22-14.07,6.91a4.17,4.17,0,0,0-2.25,1.86l-5.16,9.08h9.87l2.63-4.43a8.49,8.49,0,0,0,9.45-2.64l14.33-5Z"
          style="fill: rgb(255, 190, 157); transform-origin: 228.55px 234.135px"
          id="elxy10wf7bxuc"
          class="animable"
        ></path>
        <path
          d="M334.21,111.64a6.4,6.4,0,0,1,12.06,1.44,18,18,0,0,0,.67,3.75,3.83,3.83,0,0,0,4.32,2.07,5.35,5.35,0,0,1-2.24,2.23.92.92,0,0,1,1.39.54,1.49,1.49,0,0,1-.71,1.51,4.11,4.11,0,0,1-1.69.51c-2.85.43-6,.54-8.4-1-2.62-1.69-3.78-4.87-4.76-7.82"
          style="fill: rgb(38, 50, 56); transform-origin: 342.735px 115.967px"
          id="el34x2ruppydu"
          class="animable"
        ></path>
        <path
          d="M306.91,103.38c-3,.26-5.5,2.47-7.24,4.93s-2.95,5.28-4.77,7.68c-1.36,1.79-3.09,3.41-3.76,5.55-1.07,3.42.81,7.36-.68,10.61-.85,1.84-2.63,3.09-3.51,4.91-1.1,2.27-.6,5,.07,7.4a8.67,8.67,0,0,0,2.45,4.55,8.49,8.49,0,0,0,3.81,1.43,50.86,50.86,0,0,0,30.31-3.75"
          style="fill: rgb(38, 50, 56); transform-origin: 304.947px 127.329px"
          id="elxzj7ow987p9"
          class="animable"
        ></path>
        <path
          d="M330.34,120.49A15.53,15.53,0,0,0,314,105.29c-8.31.42-14.7,7.58-14.75,15.9,0,7.29.09,14.63.51,17.24,1.25,7.87,6.31,10.27,8.31,10.92a10.34,10.34,0,0,0,2.16.21h0a.06.06,0,0,1,.05.06l-.43,9.31c.07,6,4.15,10.85,10.2,10.85s10.05-5.23,10.09-11.3Z"
          style="
            fill: rgb(255, 190, 157);
            transform-origin: 314.795px 137.524px;
          "
          id="elskfqqbby7fb"
          class="animable"
        ></path>
        <path
          d="M302.22,125.27a1.27,1.27,0,0,0,1.23,1.27,1.2,1.2,0,0,0,1.3-1.14,1.26,1.26,0,0,0-1.22-1.28A1.22,1.22,0,0,0,302.22,125.27Z"
          style="fill: rgb(38, 50, 56); transform-origin: 303.485px 125.33px"
          id="elbcsimjdwajn"
          class="animable"
        ></path>
        <path
          d="M301.41,123.06c.15.17,1.11-.54,2.48-.54s2.36.68,2.51.52-.09-.37-.53-.69a3.45,3.45,0,0,0-2-.62,3.29,3.29,0,0,0-2,.64C301.47,122.69,301.33,123,301.41,123.06Z"
          style="fill: rgb(38, 50, 56); transform-origin: 303.917px 122.408px"
          id="el4o9nb31xh1k"
          class="animable"
        ></path>
        <path
          d="M315.56,125.27a1.25,1.25,0,0,0,1.22,1.27,1.2,1.2,0,0,0,1.3-1.14,1.26,1.26,0,0,0-1.22-1.28A1.21,1.21,0,0,0,315.56,125.27Z"
          style="fill: rgb(38, 50, 56); transform-origin: 316.82px 125.33px"
          id="elgc3puns5zmq"
          class="animable"
        ></path>
        <path
          d="M314.81,123.32c.16.16,1.12-.55,2.48-.55s2.36.69,2.51.52-.08-.37-.52-.69a3.55,3.55,0,0,0-2-.62,3.39,3.39,0,0,0-2,.64C314.88,122.94,314.73,123.24,314.81,123.32Z"
          style="fill: rgb(38, 50, 56); transform-origin: 317.318px 122.662px"
          id="elhogi5swaiqr"
          class="animable"
        ></path>
        <path
          d="M309.86,133.43a8.49,8.49,0,0,0-2.21-.4c-.35,0-.68-.11-.74-.34a1.79,1.79,0,0,1,.23-1l1-2.65c1.43-3.77,2.47-6.88,2.31-6.93a45.7,45.7,0,0,0-2.86,6.73c-.35.93-.67,1.82-1,2.67a2,2,0,0,0-.18,1.36.89.89,0,0,0,.57.51,2.91,2.91,0,0,0,.6.09A8.81,8.81,0,0,0,309.86,133.43Z"
          style="fill: rgb(38, 50, 56); transform-origin: 308.414px 127.818px"
          id="elcxv61arqcx8"
          class="animable"
        ></path>
        <path
          d="M310.3,149.56s5.27.43,11.85-3.53c0,0-2.08,7-12,6Z"
          style="fill: rgb(235, 153, 110); transform-origin: 316.15px 149.079px"
          id="el6103t3hjy1b"
          class="animable"
        ></path>
        <path
          d="M310.44,136.71a2.48,2.48,0,0,1,2.2-.91,2.28,2.28,0,0,1,1.55.83,1.44,1.44,0,0,1,.11,1.63,1.64,1.64,0,0,1-1.79.45,5,5,0,0,1-1.74-1,1.48,1.48,0,0,1-.39-.4.47.47,0,0,1,0-.51"
          style="
            fill: rgb(235, 153, 110);
            transform-origin: 312.403px 137.298px;
          "
          id="elueqcc4wd7n"
          class="animable"
        ></path>
        <path
          d="M313.87,134.37c-.22,0-.22,1.47-1.49,2.52s-2.84.89-2.85,1.09.35.29,1,.31a3.72,3.72,0,0,0,2.38-.83,3.19,3.19,0,0,0,1.15-2.11C314.14,134.73,314,134.36,313.87,134.37Z"
          style="fill: rgb(38, 50, 56); transform-origin: 311.807px 136.33px"
          id="elnbrcrod792"
          class="animable"
        ></path>
        <path
          d="M314.2,120.93c.13.37,1.49.19,3.09.39s2.9.62,3.11.29c.09-.16-.13-.51-.65-.86a5.44,5.44,0,0,0-4.73-.53C314.43,120.46,314.14,120.75,314.2,120.93Z"
          style="fill: rgb(38, 50, 56); transform-origin: 317.306px 120.819px"
          id="el02y90fl9avp4"
          class="animable"
        ></path>
        <path
          d="M301.91,120.27c.24.31,1.18,0,2.31,0s2.08.21,2.3-.12c.1-.16,0-.47-.47-.76a3.32,3.32,0,0,0-3.72.12C301.93,119.8,301.8,120.12,301.91,120.27Z"
          style="fill: rgb(38, 50, 56); transform-origin: 304.214px 119.643px"
          id="el9q1t94wi0lj"
          class="animable"
        ></path>
        <path
          d="M297.44,119.48c2,2.26,7,0,9.84-2.21a7.52,7.52,0,0,0,9.85-3.58c2.12,2,5.64.74,6.6-2s-.31-6.19-2.46-8.17a11.79,11.79,0,0,0-7.68-2.83A16.67,16.67,0,0,0,297.83,110c-1.34,2.85-1.86,6.5-.39,9.47"
          style="fill: rgb(38, 50, 56); transform-origin: 310.305px 110.544px"
          id="elcnn5ng9b1y"
          class="animable"
        ></path>
        <path
          d="M320.77,103.38c5.71-2,12.22,3.05,13.42,7.15,2.08,7.11,1.23,8.16,3.06,11.45,1.2,2.14,4.52,4,6,6.19,1.63,2.32,2.24,6.56,1.75,9.36-.41,2.36-2.52,9.08-2.52,9.08s-8.77,1-13.4.5a7.42,7.42,0,0,1-3.37-1c-1-.64.4-3.56.08-4.67-.93-3.15-3.61-4.66-3.38-7.94.3-4.35,3.58-5,2.88-9.36-.37-2.29-4.27-5.08-4.91-7.3s-3.12-9.95.35-13.44"
          style="fill: rgb(38, 50, 56); transform-origin: 332.044px 125.089px"
          id="elvvpygd1w7xg"
          class="animable"
        ></path>
        <path
          d="M335.36,116.94c-.15,0-.05-1.48-.34-3.23s-.82-3.12-.68-3.18a5.86,5.86,0,0,1,1.19,3.09A5.72,5.72,0,0,1,335.36,116.94Z"
          style="fill: rgb(64, 123, 255); transform-origin: 335.008px 113.735px"
          id="elz3d4cha2oc"
          class="animable"
        ></path>
        <path
          d="M334.83,114.5s-.2.14-.61.24a6.09,6.09,0,0,1-1.78.17,7,7,0,0,1-2.59-.64,7.39,7.39,0,0,1-2.57-2A14.13,14.13,0,0,0,325.1,110a7.21,7.21,0,0,0-2.24-1.12,16.23,16.23,0,0,0-2.33-.4,1.35,1.35,0,0,1,.65-.12,6.09,6.09,0,0,1,1.77.16,7.23,7.23,0,0,1,2.43,1.09,13.34,13.34,0,0,1,2.29,2.28,7.23,7.23,0,0,0,4.8,2.61A17.63,17.63,0,0,0,334.83,114.5Z"
          style="fill: rgb(69, 90, 100); transform-origin: 327.68px 111.634px"
          id="elapxdl6wdgo"
          class="animable"
        ></path>
        <path
          d="M350.46,121.38a1.28,1.28,0,0,1-.72.36,3.32,3.32,0,0,1-2.18-.29,5.15,5.15,0,0,1-2.27-2.32c-.6-1.07-1-2.35-1.56-3.57a5.46,5.46,0,0,0-2.36-2.77,4.31,4.31,0,0,0-2.94-.25,5.33,5.33,0,0,0-2.37,1.52,1.57,1.57,0,0,1,.42-.68,4.06,4.06,0,0,1,1.84-1.19,4.56,4.56,0,0,1,3.25.16,5.76,5.76,0,0,1,2.63,3c.6,1.27,1,2.53,1.51,3.56a4.86,4.86,0,0,0,2,2.22,3.31,3.31,0,0,0,2,.42C350.18,121.48,350.43,121.34,350.46,121.38Z"
          style="fill: rgb(69, 90, 100); transform-origin: 343.26px 116.887px"
          id="eljpwwqvna7ys"
          class="animable"
        ></path>
        <path
          d="M334.85,113.6s-.25.12-.72.08a3.63,3.63,0,0,1-1.84-.77,7.54,7.54,0,0,1-1.88-2.25,16.84,16.84,0,0,0-1.9-2.88,7.21,7.21,0,0,0-5.56-2.2,12.44,12.44,0,0,0-2.55.52s.19-.18.61-.38a6.18,6.18,0,0,1,1.91-.5,7.24,7.24,0,0,1,6,2.2,15.88,15.88,0,0,1,1.93,3,7.41,7.41,0,0,0,1.69,2.2A4.15,4.15,0,0,0,334.85,113.6Z"
          style="fill: rgb(69, 90, 100); transform-origin: 327.625px 109.434px"
          id="elyzokrat5jsn"
          class="animable"
        ></path>
        <path
          d="M337.36,147.63s.36-.55,1-1.55a19.28,19.28,0,0,0,2-4.51,13.91,13.91,0,0,0,.25-7.18,11.2,11.2,0,0,0-5-7c-1.35-.8-2.84-1.34-4.16-2.15a7.42,7.42,0,0,1-3-3.29,24,24,0,0,1-1.28-3.77,18,18,0,0,0-1.15-3.2,5.74,5.74,0,0,0-3.51-3.15,4.86,4.86,0,0,0-1.78-.11s.15-.08.46-.14a3.44,3.44,0,0,1,1.37,0,5.88,5.88,0,0,1,3.79,3.18,17.89,17.89,0,0,1,1.23,3.25,22.93,22.93,0,0,0,1.31,3.68,6.89,6.89,0,0,0,2.81,3.06c1.26.78,2.75,1.31,4.16,2.15a11.59,11.59,0,0,1,5.17,7.37,14,14,0,0,1-.37,7.4,17.36,17.36,0,0,1-2.18,4.51,11.64,11.64,0,0,1-.8,1.09C337.49,147.52,337.38,147.64,337.36,147.63Z"
          style="fill: rgb(69, 90, 100); transform-origin: 331.045px 129.571px"
          id="elhj4rr10kbd"
          class="animable"
        ></path>
        <path
          d="M294.14,150.05s-.11-.08-.27-.28a7.78,7.78,0,0,1-.63-.91A10.31,10.31,0,0,1,291.9,145a10.76,10.76,0,0,1,.8-6,26.34,26.34,0,0,1,1.8-3.09,7.44,7.44,0,0,0,1.29-3.41c.17-2.52-1-4.87-1.16-7.17a12.21,12.21,0,0,1,.77-6,9.66,9.66,0,0,1,2.3-3.36,7.13,7.13,0,0,1,.85-.71,1.42,1.42,0,0,1,.33-.21s-.39.37-1,1.06a10.53,10.53,0,0,0-2.1,3.36,12.24,12.24,0,0,0-.64,5.8,26.11,26.11,0,0,0,.72,3.44,11.74,11.74,0,0,1,.49,3.84,8,8,0,0,1-1.37,3.63,27.43,27.43,0,0,0-1.8,3,10.62,10.62,0,0,0-.88,5.74,11.28,11.28,0,0,0,1.15,3.8C293.86,149.6,294.18,150,294.14,150.05Z"
          style="fill: rgb(69, 90, 100); transform-origin: 295.327px 132.55px"
          id="el9av58izlvdj"
          class="animable"
        ></path>
        <path
          d="M312.46,101.73a25.31,25.31,0,0,0-3.75.77,13.92,13.92,0,0,0-7.22,5.36,17.16,17.16,0,0,0-2.58,8.7,22.54,22.54,0,0,0,.36,3.81,3.24,3.24,0,0,1-.36-1,12.07,12.07,0,0,1-.37-2.83,16.59,16.59,0,0,1,2.51-9,13.66,13.66,0,0,1,7.55-5.43,12.41,12.41,0,0,1,2.82-.46A3.41,3.41,0,0,1,312.46,101.73Z"
          style="fill: rgb(69, 90, 100); transform-origin: 305.499px 111.005px"
          id="el833uiw151iv"
          class="animable"
        ></path>
        <path
          d="M305.72,114.55a2.43,2.43,0,0,1-.25-.86,9.17,9.17,0,0,1-.11-2.48,10.13,10.13,0,0,1,11.5-9.24,9.23,9.23,0,0,1,2.39.63,2.42,2.42,0,0,1,.8.44,18.09,18.09,0,0,0-3.23-.7,10.1,10.1,0,0,0-11.09,8.91A18.66,18.66,0,0,0,305.72,114.55Z"
          style="fill: rgb(69, 90, 100); transform-origin: 312.686px 108.211px"
          id="elpo080871yrr"
          class="animable"
        ></path>
        <path
          d="M351.26,256.69,340.45,214.9l3.74-16.35,5.39-30.69s-4.95-6.77-17.77-7.42l-.86-6.21L309,155l-.41,5.87a23.91,23.91,0,0,0-15.36,12v0l-15.59,29.32L235,223.53l5.63,13.21s42.8-16.22,46.42-17.81c2.41-1.05,5-5.49,7.29-10.25,1.71,8.18,3.48,14.66,3.48,14.66l-.37,32.06Z"
          style="fill: rgb(64, 123, 255); transform-origin: 293.13px 205.46px"
          id="elnywo5yrkzfe"
          class="animable"
        ></path>
        <path
          d="M332.14,171.86s-.56.16-1.55.46-2.42.75-4.18,1.35-3.84,1.33-6.25,1.9a14,14,0,0,1-3.89.43,10.19,10.19,0,0,1-4-1.36c-1.26-.67-2.42-1.42-3.6-2a16.47,16.47,0,0,0-3.54-1.33,17.69,17.69,0,0,0-6.39-.4,22.86,22.86,0,0,0-5.72,1.63,1.54,1.54,0,0,1,.35-.23,9.32,9.32,0,0,1,1.07-.54,16.58,16.58,0,0,1,4.25-1.23,17.21,17.21,0,0,1,10.21,1.64c1.21.61,2.38,1.36,3.62,2a9.83,9.83,0,0,0,3.77,1.31,13.94,13.94,0,0,0,3.74-.39c2.38-.53,4.48-1.24,6.25-1.78s3.22-1,4.24-1.2c.51-.12.91-.19,1.18-.23A1.13,1.13,0,0,1,332.14,171.86Z"
          style="fill: rgb(250, 250, 250); transform-origin: 312.58px 173.182px"
          id="elvwsem6h9gm"
          class="animable"
        ></path>
        <path
          d="M338.75,193.07c0,.06-.84-.15-2.38-.23a20.13,20.13,0,0,0-6.4.87,58.84,58.84,0,0,0-8.92,3.66,40.14,40.14,0,0,1-11.24,3.91,18.54,18.54,0,0,1-3.15.17,14.67,14.67,0,0,1-3.05-.45,19.12,19.12,0,0,1-5.25-2.41c-3.09-2-5.7-4-8.32-4.83a9.67,9.67,0,0,0-6.31-.29,10.09,10.09,0,0,0-2.11,1.09,3,3,0,0,1,.47-.42,6.46,6.46,0,0,1,1.57-.86,9.7,9.7,0,0,1,6.5.13c2.7.83,5.39,2.86,8.46,4.77a18.5,18.5,0,0,0,5.12,2.32,14.18,14.18,0,0,0,2.94.43,17.56,17.56,0,0,0,3.06-.17,40.87,40.87,0,0,0,11.11-3.82,56.46,56.46,0,0,1,9-3.59,19.1,19.1,0,0,1,6.52-.71,11.83,11.83,0,0,1,1.76.27A3.79,3.79,0,0,1,338.75,193.07Z"
          style="fill: rgb(250, 250, 250); transform-origin: 310.185px 197.03px"
          id="elgemtskldaq"
          class="animable"
        ></path>
        <path
          d="M298.73,178.45s0,.15-.1.43-.2.68-.38,1.2c-.35,1-.91,2.52-1.63,4.33s-1.6,4-2.39,6.4a31.1,31.1,0,0,0-1,3.89,16.58,16.58,0,0,0-.34,4.25,55.48,55.48,0,0,0,1.22,8.26c.52,2.52,1.07,4.77,1.53,6.67s.83,3.43,1.06,4.5c.12.53.2,1,.25,1.23a1.32,1.32,0,0,1,.05.44,11.48,11.48,0,0,1-.5-1.62L295.27,214c-.5-1.89-1.09-4.14-1.64-6.66a51.88,51.88,0,0,1-1.27-8.33,16.64,16.64,0,0,1,.36-4.38,31.42,31.42,0,0,1,1-3.94c.82-2.46,1.75-4.6,2.51-6.39s1.37-3.25,1.78-4.26A11.86,11.86,0,0,1,298.73,178.45Z"
          style="fill: rgb(38, 50, 56); transform-origin: 295.534px 199.25px"
          id="el1k9xds4krrc"
          class="animable"
        ></path>
        <path
          d="M274.31,223.92a1.53,1.53,0,0,1-.77-.09,5.42,5.42,0,0,1-1.84-1,10.25,10.25,0,0,1-2.09-2.32,11.17,11.17,0,0,1-1.34-3.58,24.37,24.37,0,0,0-1-3.56,7,7,0,0,0-1.67-2.44,5.06,5.06,0,0,0-2.43-1.2s.27-.08.77,0a4.12,4.12,0,0,1,1.9.93,6.92,6.92,0,0,1,1.87,2.52,20.59,20.59,0,0,1,1.1,3.63,11.44,11.44,0,0,0,1.25,3.44,10.09,10.09,0,0,0,1.92,2.29,6.41,6.41,0,0,0,1.67,1.12C274.05,223.83,274.32,223.87,274.31,223.92Z"
          style="fill: rgb(250, 250, 250); transform-origin: 268.74px 216.815px"
          id="elpbq4gcx0scm"
          class="animable"
        ></path>
        <path
          d="M249.88,233.24c-.09,0,0-.88-.22-2.28a21.17,21.17,0,0,0-1.45-5.29,20.66,20.66,0,0,0-2.72-4.75c-.86-1.12-1.52-1.7-1.46-1.77a6,6,0,0,1,1.75,1.53,17.51,17.51,0,0,1,2.91,4.79,17.25,17.25,0,0,1,1.33,5.45A6.25,6.25,0,0,1,249.88,233.24Z"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 247.048px 226.195px;
          "
          id="elvtg204xf7jq"
          class="animable"
        ></path>
        <path
          d="M340.59,214.52s-.63,0-1.76.14a27,27,0,0,0-4.73.89c-2,.53-4.24,1.36-6.78,2.28a45,45,0,0,1-8.5,2.37A33.77,33.77,0,0,1,303,218.84a31.76,31.76,0,0,1-4.46-1.91c-.51-.27-.89-.5-1.15-.65S297,216,297,216a17.24,17.24,0,0,1,1.61.74,39,39,0,0,0,4.49,1.74,34.86,34.86,0,0,0,15.67,1.2,47.11,47.11,0,0,0,8.42-2.31c2.54-.89,4.85-1.7,6.85-2.19a22.93,22.93,0,0,1,4.8-.73,10.87,10.87,0,0,1,1.32,0A1.61,1.61,0,0,1,340.59,214.52Z"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 318.795px 217.509px;
          "
          id="elv57clrnhph"
          class="animable"
        ></path>
        <g id="elpp67gic5jph">
          <g
            style="opacity: 0.3; transform-origin: 319.54px 162.135px"
            class="animable"
          >
            <path
              d="M310.14,160.89a19.54,19.54,0,0,0,18.93.09q-9.52.47-19.06.07"
              id="elfw6p798zm1u"
              class="animable"
              style="transform-origin: 319.54px 162.135px"
            ></path>
          </g>
        </g>
        <path
          d="M331.66,160.31a3.3,3.3,0,0,1-.87.3,16.91,16.91,0,0,1-2.45.52,43.42,43.42,0,0,1-8.24.28c-3.22-.14-6.11-.33-8.21-.34s-3.4.06-3.4,0a3.24,3.24,0,0,1,.9-.16c.59-.08,1.44-.14,2.5-.18,2.11-.08,5,.05,8.23.19a54.22,54.22,0,0,0,8.18-.13C330.38,160.54,331.64,160.22,331.66,160.31Z"
          style="fill: rgb(38, 50, 56); transform-origin: 320.075px 160.893px"
          id="elz14j2m087fb"
          class="animable"
        ></path>
        <path
          d="M318.2,206.4c.59,1.17,35.18,28.76,45.1,36.65a3.35,3.35,0,0,1,.52,4.72l-23.08,28.65a3.34,3.34,0,0,1-4.68.53l-44.21-35a3.34,3.34,0,0,1-.44-4.82Z"
          style="fill: rgb(38, 50, 56); transform-origin: 327.572px 242.036px"
          id="elpq6zoyt25w"
          class="animable"
        ></path>
        <path
          d="M317.67,201.51c.61,1.21,37.65,30.73,46.05,37.41a2.14,2.14,0,0,1,.33,3l-24.59,30.54a2.14,2.14,0,0,1-3,.33l-46.14-36.58a2.14,2.14,0,0,1-.29-3.08Z"
          style="fill: rgb(64, 123, 255); transform-origin: 327.012px 237.383px"
          id="elkhzjwsmdmw9"
          class="animable"
        ></path>
        <g id="elkrho4qlibob">
          <path
            d="M317.67,201.51c.61,1.22,38.36,31.29,46.28,37.6a1.83,1.83,0,0,1,.28,2.59l-25,31a1.85,1.85,0,0,1-2.59.29l-46.6-37a1.84,1.84,0,0,1-.25-2.66Z"
            style="opacity: 0.4; transform-origin: 326.988px 237.45px"
            class="animable"
          ></path>
        </g>
        <path
          d="M321.49,202.05c11.73,9.77,43.44,35,43.44,35l-24.58,30.53a4.31,4.31,0,0,1-6,.68l-42.66-33.82a4.32,4.32,0,0,1-.57-6.22l22.27-25.49A5.84,5.84,0,0,1,321.49,202.05Z"
          style="fill: rgb(69, 90, 100); transform-origin: 327.494px 234.951px"
          id="el0dhafs743dhg"
          class="animable"
        ></path>
        <polygon
          points="350.86 225.8 351.48 230.12 362.72 239.77 364.93 237.02 350.86 225.8"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 357.895px 232.785px;
          "
          id="el8ij2eclu5v9"
          class="animable"
        ></polygon>
        <path
          d="M318,268.44h0l10.6-8.84-5.15-12.26-7.14,6.48a29.77,29.77,0,0,1-5.11.89,7.64,7.64,0,0,1-2.65-.2,12.27,12.27,0,0,1-3.11-1.11c-.85-.57-1.94.2-1.62,1.06s2.62,2.48,5.86,3c2.91.45-.38,2.6-2.25,3.74a.78.78,0,0,0-.3,1C308.1,264.4,311.92,271.61,318,268.44Z"
          style="
            fill: rgb(255, 190, 157);
            transform-origin: 316.181px 258.287px;
          "
          id="elm27k8vjxbnh"
          class="animable"
        ></path>
        <path
          d="M334.82,181.64c-.79-11,9.42-22.16,15.38-12.91,6.54,10.14,20.87,34,24.4,50.2,1.65,7.55-43.61,43.55-46.4,45.77-.08.07-.81-.93-.87-1l-11.19-11.6c-.05-.07,2.63-2.81,2.7-2.88L350.71,218a.18.18,0,0,0,0-.21c-1.07-1.95-15.14-27.62-15.89-35.89Z"
          style="fill: rgb(64, 123, 255); transform-origin: 345.392px 214.998px"
          id="el8i0amf79ob5"
          class="animable"
        ></path>
        <path
          d="M360,217.79c-.07.2-2.18-.47-4.87-.53s-4.83.45-4.89.24,2.09-1.06,4.92-1S360,217.62,360,217.79Z"
          style="fill: rgb(38, 50, 56); transform-origin: 355.119px 217.162px"
          id="elgcefujzze68"
          class="animable"
        ></path>
        <path
          d="M358,221.31c-.17.14-1.46-1.29-3.53-2.34s-4-1.31-3.95-1.53,2.07-.27,4.28.89S358.15,221.22,358,221.31Z"
          style="fill: rgb(38, 50, 56); transform-origin: 354.266px 219.317px"
          id="el3nrmvf66fyu"
          class="animable"
        ></path>
        <path
          d="M350.71,218a3,3,0,0,1-.35-.59l-.91-1.72-3.24-6.37c-2.72-5.45-6.43-12.89-10.52-21.11a30.16,30.16,0,0,1-3.76-12.44c-.28-4,.88-7.79,3-10a8.32,8.32,0,0,1,3.46-2.22,7.49,7.49,0,0,1,3.08-.37,6.07,6.07,0,0,1,1.9.48c.21.09.35.19.45.24s.15.09.14.1a7.73,7.73,0,0,0-2.5-.62,7.31,7.31,0,0,0-3,.45,8.12,8.12,0,0,0-3.29,2.19c-2,2.19-3.12,5.84-2.82,9.77A30.31,30.31,0,0,0,336.15,188c4.05,8.24,7.71,15.71,10.39,21.18,1.29,2.68,2.34,4.86,3.09,6.44l.82,1.77A4.47,4.47,0,0,1,350.71,218Z"
          style="fill: rgb(38, 50, 56); transform-origin: 341.299px 190.575px"
          id="elhbfchvcwx6"
          class="animable"
        ></path>
        <g id="elkt119l5afg">
          <g
            style="opacity: 0.3; transform-origin: 337.091px 197.395px"
            class="animable"
          >
            <path
              d="M332.48,175.46c-1.16,9.75-2,19.61-1.1,29.38.48,5.15,5.93,11.6,10.22,14.49l-1.15-4.43c.63-2.87,2.73-11.94,2.73-11.94s-3.91-7.74-5.47-10.57a39.6,39.6,0,0,1-5.23-16.93"
              id="ela7q51h77n5a"
              class="animable"
              style="transform-origin: 337.091px 197.395px"
            ></path>
          </g>
        </g>
        <path
          d="M371.41,209.23s-.22.25-.69.64c-.23.19-.53.41-.89.65s-.76.52-1.23.79a24.32,24.32,0,0,1-3.42,1.67,29.34,29.34,0,0,1-4.46,1.35,28.45,28.45,0,0,1-4.62.58,22.31,22.31,0,0,1-3.81-.18,12.09,12.09,0,0,1-1.44-.26,7.35,7.35,0,0,1-1.06-.27,2.82,2.82,0,0,1-.88-.34c0-.09,1.3.3,3.43.5a26.19,26.19,0,0,0,3.74.08,34.47,34.47,0,0,0,4.53-.62,33.32,33.32,0,0,0,4.4-1.29,29.72,29.72,0,0,0,3.41-1.54C370.3,210,371.35,209.16,371.41,209.23Z"
          style="fill: rgb(250, 250, 250); transform-origin: 360.16px 212.076px"
          id="elegrbyfsb74"
          class="animable"
        ></path>
        <path
          d="M352.83,242.74a18.43,18.43,0,0,1-1-2.82,45.82,45.82,0,0,0-2.63-6.66,21.54,21.54,0,0,0-2.07-3.33,12.59,12.59,0,0,0-2.23-2.26c-1.44-1.11-2.52-1.54-2.47-1.63a2.31,2.31,0,0,1,.77.25,10.93,10.93,0,0,1,1.92,1.08,12.09,12.09,0,0,1,2.39,2.26,19.67,19.67,0,0,1,2.15,3.39,35.1,35.1,0,0,1,2.52,6.8A14.82,14.82,0,0,1,352.83,242.74Z"
          style="fill: rgb(250, 250, 250); transform-origin: 347.629px 234.39px"
          id="elea3zjsbuqws"
          class="animable"
        ></path>
        <path
          d="M341,253.79a10.13,10.13,0,0,1-2.62-1,24.8,24.8,0,0,1-5.62-3.71,25.34,25.34,0,0,1-4.43-5.08,9.36,9.36,0,0,1-1.3-2.47c.07,0,.6.88,1.61,2.26a29.23,29.23,0,0,0,9.91,8.67C340,253.3,341,253.71,341,253.79Z"
          style="fill: rgb(250, 250, 250); transform-origin: 334.015px 247.66px"
          id="eldyjyo8l6p8q"
          class="animable"
        ></path>
        <path
          d="M352.22,172a2.26,2.26,0,0,1-.78.34,12.82,12.82,0,0,1-2.25.48c-1.93.29-4.63.12-7.53.28a14.29,14.29,0,0,0-4,.75,9,9,0,0,0-2.85,1.64,9.19,9.19,0,0,0-1.87,2.41,1.83,1.83,0,0,1,.25-.81,6.13,6.13,0,0,1,1.37-1.87,8.74,8.74,0,0,1,2.93-1.82,14.28,14.28,0,0,1,4.12-.82c3-.16,5.63.06,7.53-.13S352.19,171.9,352.22,172Z"
          style="fill: rgb(250, 250, 250); transform-origin: 342.58px 174.941px"
          id="elhmfp2ibsysf"
          class="animable"
        ></path>
        <path
          d="M362.55,190.61a1.81,1.81,0,0,1-.44.81,5.08,5.08,0,0,1-2,1.47,12,12,0,0,1-3.62.73,39.38,39.38,0,0,0-4.4.57,36.22,36.22,0,0,0-7.68,2.42c-1.89.84-3,1.49-3,1.42a3,3,0,0,1,.73-.54,22.5,22.5,0,0,1,2.16-1.21,30.44,30.44,0,0,1,7.73-2.6c3.14-.68,6.1-.37,8-1.14A5.65,5.65,0,0,0,362.55,190.61Z"
          style="fill: rgb(250, 250, 250); transform-origin: 351.98px 194.323px"
          id="eluppb6vnuyhk"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--Plant--inject-20"
        class="animable"
        style="transform-origin: 130.858px 140.38px"
      >
        <path
          d="M115.41,91.19A15.21,15.21,0,0,1,126,96.7a26.82,26.82,0,0,1,5.29,10.91c2,7.63.65,16.62-1.59,24.18-8.23-3-13.24-11.86-15.5-16.5-3.57-7.35-5.57-22.78,1.26-24.1"
          style="fill: rgb(64, 123, 255); transform-origin: 121.595px 111.49px"
          id="elp0kflpl03e"
          class="animable"
        ></path>
        <path
          d="M141.44,141.13a13,13,0,0,1-.5-13,17.22,17.22,0,0,1,10.44-8.25c2-.6,4.36-.84,6.1.35a5.58,5.58,0,0,1,2.09,5.21,11.25,11.25,0,0,1-2.42,5.31c-4.06,5.4-9,9.88-15.71,10.38"
          style="fill: rgb(64, 123, 255); transform-origin: 149.533px 130.257px"
          id="elxqtk8prucq"
          class="animable"
        ></path>
        <g id="elcz7r56flsal">
          <g
            style="opacity: 0.3; transform-origin: 149.533px 130.257px"
            class="animable"
          >
            <path
              d="M141.44,141.13a13,13,0,0,1-.5-13,17.22,17.22,0,0,1,10.44-8.25c2-.6,4.36-.84,6.1.35a5.58,5.58,0,0,1,2.09,5.21,11.25,11.25,0,0,1-2.42,5.31c-4.06,5.4-9,9.88-15.71,10.38"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 149.533px 130.257px;
              "
              id="elh8k38sv0875"
              class="animable"
            ></path>
          </g>
        </g>
        <path
          d="M132.66,149.22a30.32,30.32,0,0,0-23.82-14.13c-2.45-.12-5.37.35-6.41,2.56s.46,4.78,2.16,6.55a25.91,25.91,0,0,0,28.17,5.67"
          style="fill: rgb(64, 123, 255); transform-origin: 117.424px 143.44px"
          id="el0hft4cgfrjbi"
          class="animable"
        ></path>
        <g id="elpbxay435hxm">
          <g
            style="opacity: 0.3; transform-origin: 117.424px 143.44px"
            class="animable"
          >
            <path
              d="M132.66,149.22a30.32,30.32,0,0,0-23.82-14.13c-2.45-.12-5.37.35-6.41,2.56s.46,4.78,2.16,6.55a25.91,25.91,0,0,0,28.17,5.67"
              style="
                fill: rgb(255, 255, 255);
                transform-origin: 117.424px 143.44px;
              "
              id="el665duesw1hh"
              class="animable"
            ></path>
          </g>
        </g>
        <path
          d="M141.51,167.33a9,9,0,0,1-.34-1.62c-.18-1.05-.43-2.57-.68-4.45a57.33,57.33,0,0,1-.33-14.85,28.1,28.1,0,0,1,5.5-13.72,18.63,18.63,0,0,1,3.19-3.2,12.09,12.09,0,0,1,1-.72,1.47,1.47,0,0,1,.38-.21,28,28,0,0,0-4.28,4.35,29.1,29.1,0,0,0-5.28,13.57,62.85,62.85,0,0,0,.19,14.74c.21,1.81.38,3.32.51,4.46A11.61,11.61,0,0,1,141.51,167.33Z"
          style="fill: rgb(38, 50, 56); transform-origin: 145.029px 147.945px"
          id="eluhfnt2fw2td"
          class="animable"
        ></path>
        <path
          d="M118,102.81a5,5,0,0,1,.32.63c.21.47.49,1.08.83,1.85.71,1.6,1.71,3.94,2.93,6.84,2.43,5.79,5.68,13.84,9.07,22.81s6.28,17.16,8.29,23.12c1,3,1.8,5.39,2.33,7.06.24.8.44,1.45.59,1.94a3,3,0,0,1,.18.68,3.5,3.5,0,0,1-.28-.65c-.17-.48-.4-1.11-.69-1.9-.6-1.71-1.45-4.09-2.48-7-2.1-5.92-5-14.09-8.42-23.05s-6.59-17-8.93-22.86l-2.78-6.9c-.3-.78-.55-1.41-.74-1.89A3.19,3.19,0,0,1,118,102.81Z"
          style="fill: rgb(38, 50, 56); transform-origin: 130.27px 135.275px"
          id="el8s4onn89wr9"
          class="animable"
        ></path>
        <path
          d="M112.74,141.41a10.34,10.34,0,0,1,1.58.09,14.25,14.25,0,0,1,1.83.26,21.87,21.87,0,0,1,2.41.53l1.38.38c.48.14,1,.34,1.47.51a33.69,33.69,0,0,1,3.15,1.34,34.4,34.4,0,0,1,12.11,9.83c.71,1,1.41,1.89,2,2.82.28.46.57.89.8,1.33l.66,1.28c.44.8.72,1.58,1,2.24a14,14,0,0,1,.62,1.75,10.11,10.11,0,0,1,.42,1.53c-.12,0-.7-2.15-2.38-5.35-.22-.4-.44-.82-.68-1.25s-.53-.84-.82-1.29a33.08,33.08,0,0,0-2-2.76,35.69,35.69,0,0,0-11.94-9.7c-1.08-.5-2.09-1-3.1-1.36-.49-.18-1-.39-1.43-.54l-1.36-.41C115,141.65,112.72,141.53,112.74,141.41Z"
          style="fill: rgb(38, 50, 56); transform-origin: 127.455px 153.354px"
          id="elcbkmbkg8hf"
          class="animable"
        ></path>
        <path
          d="M125.85,159.94h29.63a0,0,0,0,1,0,0v17.34a12.29,12.29,0,0,1-12.29,12.29h-5.05a12.29,12.29,0,0,1-12.29-12.29V159.94A0,0,0,0,1,125.85,159.94Z"
          style="fill: rgb(64, 123, 255); transform-origin: 140.665px 174.755px"
          id="elxoul5ts3q1a"
          class="animable"
        ></path>
        <g id="elsobqo5kxfw">
          <path
            d="M125.85,159.94h29.63a0,0,0,0,1,0,0v17.34a12.29,12.29,0,0,1-12.29,12.29h-5.05a12.29,12.29,0,0,1-12.29-12.29V159.94A0,0,0,0,1,125.85,159.94Z"
            style="opacity: 0.3; transform-origin: 140.665px 174.755px"
            class="animable"
          ></path>
        </g>
        <path
          d="M145.87,160a2.91,2.91,0,0,1-.34.88,15.14,15.14,0,0,1-1.32,2.2,17.67,17.67,0,0,1-14.56,7.43,15,15,0,0,1-2.55-.22,2.91,2.91,0,0,1-.91-.24c0-.09,1.32.17,3.45.1a18.73,18.73,0,0,0,14.28-7.29C145.22,161.2,145.78,160,145.87,160Z"
          style="fill: rgb(250, 250, 250); transform-origin: 136.03px 165.255px"
          id="elo7bwcs8itl"
          class="animable"
        ></path>
        <path
          d="M155.2,180a3,3,0,0,1-1-.2,11.77,11.77,0,0,1-2.44-1.1,12.59,12.59,0,0,1-5.35-15.47,12.18,12.18,0,0,1,1.24-2.37,3.39,3.39,0,0,1,.63-.75,26,26,0,0,0-1.52,3.25,12.71,12.71,0,0,0,5.2,15A26.28,26.28,0,0,0,155.2,180Z"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 150.368px 170.055px;
          "
          id="el3phsfhrngn"
          class="animable"
        ></path>
        <path
          d="M147.61,188.76a18.19,18.19,0,0,0-.62-4.38,13.51,13.51,0,0,0-2.21-4.21,15.31,15.31,0,0,0-4.38-3.81,16.74,16.74,0,0,0-10.28-2,36.27,36.27,0,0,0-4.35,1,5.38,5.38,0,0,1,1.1-.5,15.71,15.71,0,0,1,3.2-.81,16.33,16.33,0,0,1,10.59,1.89,15.19,15.19,0,0,1,4.5,4,13.23,13.23,0,0,1,2.18,4.4,11.35,11.35,0,0,1,.41,3.28A4.67,4.67,0,0,1,147.61,188.76Z"
          style="
            fill: rgb(250, 250, 250);
            transform-origin: 136.761px 181.302px;
          "
          id="el2o0627mkr1k"
          class="animable"
        ></path>
      </g>
      <g
        id="freepik--filing-cabinet--inject-20"
        class="animable"
        style="transform-origin: 155.515px 324.935px"
      >
        <rect
          x="58.56"
          y="189.57"
          width="93.27"
          height="270.73"
          style="fill: rgb(38, 50, 56); transform-origin: 105.195px 324.935px"
          id="el2zvk3y5t202"
          class="animable"
        ></rect>
        <rect
          x="99.78"
          y="189.57"
          width="102.12"
          height="270.73"
          style="fill: rgb(69, 90, 100); transform-origin: 150.84px 324.935px"
          id="elm1ftbaqdto7"
          class="animable"
        ></rect>
        <rect
          x="106.33"
          y="266.41"
          width="91.01"
          height="55.89"
          style="fill: rgb(69, 90, 100); transform-origin: 151.835px 294.355px"
          id="eloxegsvhqgo"
          class="animable"
        ></rect>
        <path
          d="M137,398.39h31.17a0,0,0,0,1,0,0v1.41A2.15,2.15,0,0,1,166,402H139.16a2.15,2.15,0,0,1-2.15-2.15v-1.41A0,0,0,0,1,137,398.39Z"
          style="fill: rgb(38, 50, 56); transform-origin: 152.585px 400.195px"
          id="elnysuhj9rvg"
          class="animable"
        ></path>
        <path
          d="M197.14,398.39s0,.33,0,1,0,1.64,0,2.9c0,2.58,0,6.34,0,11.17,0,9.73.07,23.72.11,40.85v.2h-.2l-91,.06h-.31v-.26c0-19.78,0-38.65,0-55.82v-.24h.24l65.66.06,18.74,0,5,0,1.27,0h.42l-.45,0h-1.3l-5,0-18.8.08-65.5.17.24-.24c0,17.17,0,36,0,55.82l-.26-.26h0l91,.05-.21.21c0-17.08.09-31,.11-40.72,0-4.85,0-8.63.05-11.22q0-1.92,0-2.94C197.12,398.74,197.14,398.39,197.14,398.39Z"
          style="fill: rgb(38, 50, 56); transform-origin: 151.495px 426.41px"
          id="elgolxm2q3m6m"
          class="animable"
        ></path>
        <rect
          x="139.86"
          y="412.04"
          width="27.1"
          height="7.32"
          style="fill: rgb(224, 224, 224); transform-origin: 153.41px 415.7px"
          id="elf4xzi4j7kjb"
          class="animable"
        ></rect>
        <rect
          x="106.33"
          y="328.62"
          width="91.01"
          height="55.89"
          style="fill: rgb(69, 90, 100); transform-origin: 151.835px 356.565px"
          id="elnm38qvwu8l"
          class="animable"
        ></rect>
        <path
          d="M136.88,335.87h31.17a0,0,0,0,1,0,0v1.41a2.15,2.15,0,0,1-2.15,2.15H139a2.15,2.15,0,0,1-2.15-2.15v-1.41A0,0,0,0,1,136.88,335.87Z"
          style="fill: rgb(38, 50, 56); transform-origin: 152.45px 337.65px"
          id="elhb2b3dii2bv"
          class="animable"
        ></path>
        <path
          d="M198,335.87s0,.33,0,1,0,1.64,0,2.9c0,2.58,0,6.34.05,11.17,0,9.73.07,23.72.11,40.85v.2H198L107,392h-.31v-.26c0-19.78,0-38.65,0-55.82v-.24h.24l65.66.06,18.74.05,5,0,1.27,0H198l-.45,0h-1.3l-5,0-18.8.08-65.5.18.24-.25c0,17.17,0,36,0,55.82l-.26-.26h0l91,.05-.21.21c.05-17.08.09-31,.11-40.72,0-4.85,0-8.63,0-11.22q0-1.92,0-2.94C198,336.23,198,335.87,198,335.87Z"
          style="fill: rgb(38, 50, 56); transform-origin: 152.425px 363.84px"
          id="elxwnqpi82g2p"
          class="animable"
        ></path>
        <rect
          x="138.92"
          y="349.09"
          width="27.1"
          height="7.32"
          style="fill: rgb(224, 224, 224); transform-origin: 152.47px 352.75px"
          id="eltzflpnve3f"
          class="animable"
        ></rect>
        <path
          d="M137.21,197h31.17a0,0,0,0,1,0,0v1.41a2.15,2.15,0,0,1-2.15,2.15H139.36a2.15,2.15,0,0,1-2.15-2.15V197A0,0,0,0,1,137.21,197Z"
          style="fill: rgb(38, 50, 56); transform-origin: 152.795px 198.78px"
          id="elkjs3wffggmn"
          class="animable"
        ></path>
        <path
          d="M197.34,197s0,.32,0,1,0,1.64,0,2.91c0,2.57,0,6.34.05,11.17,0,9.72.06,23.71.11,40.84v.2h-.21l-91,.05H106v-.26c0-19.78,0-38.64,0-55.81v-.24h.24l65.65.06,18.75,0,5,0h1.27l.42,0h-.45l-1.29,0-5,0-18.79.08-65.5.18.24-.24c0,17.17,0,36,0,55.81l-.26-.26h0l91,.06-.2.2c0-17.08.08-31,.11-40.72,0-4.84,0-8.62.05-11.21,0-1.28,0-2.26,0-2.94S197.34,197,197.34,197Z"
          style="fill: rgb(38, 50, 56); transform-origin: 151.75px 225.015px"
          id="el53c3gqk0nsw"
          class="animable"
        ></path>
        <rect
          x="138.76"
          y="208.86"
          width="27.1"
          height="7.32"
          style="fill: rgb(224, 224, 224); transform-origin: 152.31px 212.52px"
          id="elu2olgsy87o8"
          class="animable"
        ></rect>
        <path
          d="M106.62,284.53v-21a3.28,3.28,0,0,1,3.28-3.28h55.94a3.23,3.23,0,0,1,3.23,3.23v21Z"
          style="fill: rgb(64, 123, 255); transform-origin: 137.845px 272.39px"
          id="ellpqqdnkzgqc"
          class="animable"
        ></path>
        <g id="eleyash709d1w">
          <path
            d="M106.62,284.53v-21a3.28,3.28,0,0,1,3.28-3.28h55.94a3.23,3.23,0,0,1,3.23,3.23v21Z"
            style="opacity: 0.3; transform-origin: 137.845px 272.39px"
            class="animable"
          ></path>
        </g>
        <path
          d="M112.34,284.53V266.07a.34.34,0,0,1,.32-.34h56.41v18.8Z"
          style="fill: rgb(250, 250, 250); transform-origin: 140.705px 275.13px"
          id="elccwr9o4vzza"
          class="animable"
        ></path>
        <path
          d="M181.78,262.19l-1.61,22.34H119.46l1.62-20.73c.16-2,1.54-3.52,3.19-3.51l29.53.16h0a2.29,2.29,0,0,1,2-2.47H178.6C180.47,258,181.94,259.93,181.78,262.19Z"
          style="fill: rgb(64, 123, 255); transform-origin: 150.626px 271.255px"
          id="el6bl8vzj7xio"
          class="animable"
        ></path>
        <path
          d="M131.48,282.36v-21a3.28,3.28,0,0,1,3.29-3.28h55.94a3.23,3.23,0,0,1,3.23,3.23v21.06Z"
          style="fill: rgb(64, 123, 255); transform-origin: 162.71px 270.225px"
          id="elm5kxmlhiakl"
          class="animable"
        ></path>
        <g id="elmjrezkuqstq">
          <path
            d="M131.48,282.36v-21a3.28,3.28,0,0,1,3.29-3.28h55.94a3.23,3.23,0,0,1,3.23,3.23v21.06Z"
            style="opacity: 0.3; transform-origin: 162.71px 270.225px"
            class="animable"
          ></path>
        </g>
        <path
          d="M137.21,282.36V263.89a.32.32,0,0,1,.32-.33h56.41v18.8Z"
          style="fill: rgb(250, 250, 250); transform-origin: 165.575px 272.96px"
          id="elfkcsjw4jyp"
          class="animable"
        ></path>
        <path
          d="M206.65,260,205,282.36H144.32L146,261.62a3.5,3.5,0,0,1,3.19-3.5l29.53.15h0a2.29,2.29,0,0,1,2-2.47h22.77C205.34,255.8,206.81,257.75,206.65,260Z"
          style="fill: rgb(64, 123, 255); transform-origin: 175.491px 269.08px"
          id="el772xayyi8fn"
          class="animable"
        ></path>
        <path
          d="M166.08,283.57v-21a3.28,3.28,0,0,1,3.28-3.29H225.3a3.23,3.23,0,0,1,3.23,3.24v21.05Z"
          style="fill: rgb(64, 123, 255); transform-origin: 197.305px 271.425px"
          id="elddg7jutyhxn"
          class="animable"
        ></path>
        <g id="elu6a874sm84">
          <path
            d="M166.08,283.57v-21a3.28,3.28,0,0,1,3.28-3.29H225.3a3.23,3.23,0,0,1,3.23,3.24v21.05Z"
            style="opacity: 0.3; transform-origin: 197.305px 271.425px"
            class="animable"
          ></path>
        </g>
        <path
          d="M171.81,283.57V265.11a.33.33,0,0,1,.31-.34h56.41v18.8Z"
          style="fill: rgb(250, 250, 250); transform-origin: 200.17px 274.17px"
          id="eleme4mnaoa06"
          class="animable"
        ></path>
        <rect
          x="174.65"
          y="259.96"
          width="57.22"
          height="33.54"
          style="fill: rgb(224, 224, 224); transform-origin: 203.26px 276.73px"
          id="elpgs0vugqpm8"
          class="animable"
        ></rect>
        <rect
          x="178.92"
          y="245.07"
          width="57.66"
          height="35.76"
          style="fill: rgb(245, 245, 245); transform-origin: 207.75px 262.95px"
          id="el990tpcejdhn"
          class="animable"
        ></rect>
        <path
          d="M189.43,278.57c-.15,0-.26-6.18-.26-13.8s.11-13.8.26-13.8.26,6.18.26,13.8S189.57,278.57,189.43,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 189.43px 264.77px"
          id="elztciomy36t"
          class="animable"
        ></path>
        <path
          d="M193.52,278.57c-.14,0-.26-6.18-.26-13.8s.12-13.8.26-13.8.26,6.18.26,13.8S193.67,278.57,193.52,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 193.52px 264.77px"
          id="elk4xlhrag6k"
          class="animable"
        ></path>
        <path
          d="M198,278.57c-.15,0-.26-6.18-.26-13.8s.11-13.8.26-13.8.26,6.18.26,13.8S198.13,278.57,198,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 198px 264.77px"
          id="elh54ovt1qo65"
          class="animable"
        ></path>
        <path
          d="M202.45,278.57c-.14,0-.26-6.18-.26-13.8s.12-13.8.26-13.8.26,6.18.26,13.8S202.59,278.57,202.45,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 202.45px 264.77px"
          id="el3aa7vi38ca5"
          class="animable"
        ></path>
        <path
          d="M206.91,278.57c-.14,0-.26-6.18-.26-13.8s.12-13.8.26-13.8.26,6.18.26,13.8S207.06,278.57,206.91,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 206.91px 264.77px"
          id="elon6okpzqtqb"
          class="animable"
        ></path>
        <path
          d="M211.38,278.57c-.15,0-.26-6.18-.26-13.8s.11-13.8.26-13.8.26,6.18.26,13.8S211.52,278.57,211.38,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 211.38px 264.77px"
          id="elxyzpjflh0p"
          class="animable"
        ></path>
        <path
          d="M215.84,278.57c-.14,0-.26-6.18-.26-13.8s.12-13.8.26-13.8.26,6.18.26,13.8S216,278.57,215.84,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 215.84px 264.77px"
          id="eljjkh6xcplob"
          class="animable"
        ></path>
        <path
          d="M220.3,278.57c-.14,0-.26-6.18-.26-13.8s.12-13.8.26-13.8.26,6.18.26,13.8S220.45,278.57,220.3,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 220.3px 264.77px"
          id="elq9wdlkpygw"
          class="animable"
        ></path>
        <path
          d="M224.77,278.57c-.15,0-.27-6.18-.27-13.8s.12-13.8.27-13.8.26,6.18.26,13.8S224.91,278.57,224.77,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 224.765px 264.77px"
          id="elcc57a31dfoc"
          class="animable"
        ></path>
        <path
          d="M229.23,278.57c-.15,0-.26-6.18-.26-13.8s.11-13.8.26-13.8.26,6.18.26,13.8S229.37,278.57,229.23,278.57Z"
          style="fill: rgb(38, 50, 56); transform-origin: 229.23px 264.77px"
          id="elx32rp71bro"
          class="animable"
        ></path>
        <path
          d="M241.24,265.18l-1.6,18.39H178.92l1.63-17.07a3.19,3.19,0,0,1,3.18-2.89l29.54.13h0a2,2,0,0,1,2-2h22.77A3.18,3.18,0,0,1,241.24,265.18Z"
          style="fill: rgb(64, 123, 255); transform-origin: 210.085px 272.655px"
          id="el2n7kwowbqyz"
          class="animable"
        ></path>
        <rect
          x="106.1"
          y="273.35"
          width="91.01"
          height="55.89"
          style="fill: rgb(38, 50, 56); transform-origin: 151.605px 301.295px"
          id="el8dy2wytgfop"
          class="animable"
        ></rect>
        <rect
          x="161.17"
          y="273.35"
          width="91.01"
          height="55.89"
          style="fill: rgb(69, 90, 100); transform-origin: 206.675px 301.295px"
          id="elbdqeewaabp"
          class="animable"
        ></rect>
        <path
          d="M252.18,329s0-.35,0-1,0-1.65,0-2.92c0-2.56,0-6.31,0-11.12,0-9.62-.07-23.46-.11-40.42l.2.21-91,.05h0l.26-.26c0,19.66,0,38.41,0,55.47l-.24-.24,65.46.12,18.79.06,5,0,1.29,0h.45l-.42,0h-1.27l-5,0-18.74.07-65.62.11h-.24V329c0-17.06,0-35.81,0-55.47v-.26h.27l91,.06h.21v.2c0,17-.09,30.89-.11,40.54,0,4.79,0,8.53-.05,11.08q0,1.89,0,2.88C252.2,328.65,252.18,329,252.18,329Z"
          style="fill: rgb(38, 50, 56); transform-origin: 206.73px 301.2px"
          id="elf7onz0ntf9a"
          class="animable"
        ></path>
        <path
          d="M193.92,273.35h31.17a0,0,0,0,1,0,0v1.23a2.15,2.15,0,0,1-2.15,2.15H196.07a2.15,2.15,0,0,1-2.15-2.15v-1.23A0,0,0,0,1,193.92,273.35Z"
          style="fill: rgb(38, 50, 56); transform-origin: 209.505px 275.04px"
          id="el614fe47bihh"
          class="animable"
        ></path>
        <rect
          x="196.54"
          y="285.88"
          width="27.1"
          height="7.32"
          style="fill: rgb(224, 224, 224); transform-origin: 210.09px 289.54px"
          id="elbzn2ygu5v3e"
          class="animable"
        ></rect>
        <path
          d="M222.51,238.52A55.52,55.52,0,0,0,221,249.17c0,.46,0,1,.4,1.25s1-.44,1.33-.85c.64-.73,3-4.82,3-4.82a6.79,6.79,0,0,0,6.17-6.59"
          style="fill: rgb(255, 190, 157); transform-origin: 226.45px 244.317px"
          id="elqo87qsrufp7"
          class="animable"
        ></path>
        <path
          d="M231.87,238.16a2.65,2.65,0,0,1,0,.74,6.51,6.51,0,0,1-.51,2,6.83,6.83,0,0,1-1.92,2.53,7.44,7.44,0,0,1-3.73,1.56l.18-.12c-.75,1.3-1.58,2.72-2.52,4.16-.15.21-.28.42-.46.64s-.31.38-.5.58a1.41,1.41,0,0,1-.75.47.81.81,0,0,1-.83-.5,3.14,3.14,0,0,1-.12-1.52c0-.46.06-.91.1-1.35a46.05,46.05,0,0,1,.63-4.66c.24-1.31.49-2.36.69-3.08a4.59,4.59,0,0,1,.36-1.1,4.76,4.76,0,0,1-.17,1.14c-.13.74-.33,1.79-.52,3.11s-.39,2.88-.51,4.62l-.09,1.35a2.84,2.84,0,0,0,.09,1.26c.07.16.21.25.29.23a1.17,1.17,0,0,0,.46-.33l.47-.56a6.85,6.85,0,0,0,.42-.59c.93-1.42,1.77-2.84,2.54-4.12l.06-.11h.12a7.1,7.1,0,0,0,3.53-1.4,6.54,6.54,0,0,0,1.9-2.33A11.29,11.29,0,0,0,231.87,238.16Z"
          style="
            fill: rgb(235, 153, 110);
            transform-origin: 226.281px 244.442px;
          "
          id="elfk3vtwde0vv"
          class="animable"
        ></path>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#32DFEC"
            flood-opacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            result="DILATED"
            operator="dilate"
            radius="2"
          ></feMorphology>
          <feFlood
            flood-color="#ff0000"
            flood-opacity="0.5"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix
            type="matrix"
            values="0   0   0   0   0                0   1   0   0   0                0   0   0   0   0                0   0   0   1   0 "
          ></feColorMatrix>
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style>
svg#freepik_stories-filing-system:not(.animated) .animable {
  opacity: 0;
}
svg#freepik_stories-filing-system.animated
  #freepik--background-complete--inject-20 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 0s;
}
svg#freepik_stories-filing-system.animated #freepik--Floor--inject-20 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-filing-system.animated #freepik--Box--inject-20 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) lightSpeedRight;
  animation-delay: 0s;
}
svg#freepik_stories-filing-system.animated #freepik--Character--inject-20 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideDown;
  animation-delay: 0s;
}
svg#freepik_stories-filing-system.animated #freepik--Plant--inject-20 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) slideUp;
  animation-delay: 0s;
}
svg#freepik_stories-filing-system.animated #freepik--filing-cabinet--inject-20 {
  animation: 1s 1 forwards cubic-bezier(0.36, -0.01, 0.5, 1.38) fadeIn;
  animation-delay: 0s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lightSpeedRight {
  from {
    transform: translate3d(50%, 0, 0) skewX(-20deg);
    opacity: 0;
  }
  60% {
    transform: skewX(10deg);
    opacity: 1;
  }
  80% {
    transform: skewX(-2deg);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: inherit;
  }
}
</style>
